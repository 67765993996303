import React, { FC } from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

export const CreateComponent: FC = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Business plan name" source="name" />
        <TextInput label="Business plan description" source="description" fullWidth />
      </SimpleForm>
    </Create>
  )
}