import React, { FC } from "react";
import { Datagrid, DateField, List, TextField } from "react-admin";

export const ListComponent: FC = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <TextField source="username" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField label="Company name" source="company.name" />
        <TextField label="Role" source="role.value" />
        <DateField source="created_at" showTime />
        <DateField source="updated_at" showTime />
      </Datagrid>
    </List>
  );
};