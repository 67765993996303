import React, { ChangeEvent, useState } from 'react';
import { Button } from 'react-admin';
import { passwordFields } from '../../constants/data';
import { changePassword } from '../../helpers/adminQueries';
import { IField } from '../Drawer/interfaces';
import { IPassword } from './interfaces';
import './style.css'

const passwrodInitValues: IPassword = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

const ChangePassword = () => {
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState<IPassword>(passwrodInitValues);

  const validatePassword = (): boolean => {
    const { oldPassword, newPassword, confirmPassword } = password;
    let isValid = true;
    if (newPassword.length < 6) {
      setError('The length of the new password must be greater than 6 characters ');
      isValid = false;
    }
    if (newPassword !== confirmPassword) {
      setError('New password and confirm password must be unique');
      isValid = false;
    }
    if (oldPassword === newPassword) {
      setError('The new password must be different from the old one');
      isValid = false;
    }
    return isValid;
  }

  const clearFields = () => {
    setError('');
    setSuccess('');
    setPassword(passwrodInitValues);
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    clearFields();
    setPassword({ ...password, [id]: value })
  }

  const submit = async () => {
    if (!validatePassword()) return;
    const res = await changePassword(password);
    if (res.status) {
      setSuccess(res.message);
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        clearFields();
      }, 2000);
    }
    else setError(res?.message || 'Error');
  }

  const renderFields = ({ key, value }: IField) => {
    const id = value.toString();
    return <div key={id} className="change-password-field">
      <label htmlFor={id}>{key}</label>
      <input onChange={onChange} type={passwordVisible ? "text" : "password"} id={id} value={password[id]} />
    </div>
  }

  return (
    <div className="change-password">
      <div className="change-password-title">
        <h2>Change password</h2>
        <Button
          onClick={() => setPasswordVisible(!passwordVisible)}
          label={`${passwordVisible ? 'Hide' : 'Show'} password`}
        />
      </div>
      {passwordFields.map(renderFields)}
      <div className="change-password-field">
        <span className="change-password-error">{error}</span>
        <span className="change-password-success">{success}</span>
      </div>
      <div className="change-password-field">
        <Button onClick={submit} label="Submit" />
      </div>
    </div>
  )
}

export default ChangePassword;