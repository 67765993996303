import React, { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import { Button } from 'react-admin';
import { IDrawerProps } from './interfaces';
import './style.css';

export const CustomPermissionDrawer: FC<IDrawerProps> = (
  {
    permissionRequest,
    isOpen,
    closeDrawer,
    submit,
    submit2,
  }) => {
  return (
    <Drawer
      sx={{ width: 400, flexShrink: 0 }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className='approvals-drawer'>
        <div className="approvals-drawer-head">
          <h3>Layer permission request</h3>
          <Button label='Close' onClick={closeDrawer} />
        </div>
        <div className="approvals-drawer-field">
          <h4>Company name: </h4>
          <span>{permissionRequest.business_plan.company.name}</span>
        </div>
        <div className="approvals-drawer-field">
          <h4>Business plan: </h4>
          <span>{permissionRequest.business_plan.name}</span>
        </div>
        <div className="approvals-drawer-field">
          <h4>Customer: </h4>
          <span>{permissionRequest.owner.first_name + " " + permissionRequest.owner.last_name}</span>
        </div>
        <div className="approvals-drawer-field">
          <h4>Layer type: </h4>
          <span>{permissionRequest.layer_type}</span>
        </div>
        <div className="approvals-drawer-btns">
          <Button label='Approve' onClick={submit} />
          <Button label='Reject' onClick={submit2} />
        </div>
      </div>
    </Drawer>
  )
};