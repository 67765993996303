import React, { useEffect, useState } from 'react';
import { getDashboardItemsCount } from '../../helpers/adminQueries';
import './style.css';

interface ICounts {
  approvalsCount: number
  businessPlansCount: number
  companiesCount: number
}

const Dashboard = () => {
  const [counts, setCounts] = useState<ICounts | null>(null);

  useEffect(() => {
    if (!counts) {
      getDashboardItemsCount().then(data => {
        setCounts(data || null);
      })
    }
  }, []);

  return (
    <div className='dashboard'>
      <h2>Dashboard</h2>
      <div className="dashboard-wrap">
        <div className="dashboard-item">
          <h4>Approvals</h4>
          <span>{counts?.approvalsCount || 0}</span>
        </div>
        <div className="dashboard-item">
          <h4>Registered Companies</h4>
          <span>{counts?.companiesCount || 0}</span>
        </div>
        <div className="dashboard-item">
          <h4>Business plans</h4>
          <span>{counts?.businessPlansCount || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;