import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from 'react-router';
import authProvider from "./auth/auth-provider";
import { dataProvider } from "./dataProvider";
import users from "./components/Users";
import permissions from "./components/Permissions";
import logs from "./components/Logs";
import approvals from "./components/Approvals";
import businessPlans from './components/BusinessPlans';
import companies from './components/Companies';
import Dashboard from "./components/Dashboard";
import ChangePassword from "./components/ChangePassword";
import Layout from './components/Layout';
import Person from '@mui/icons-material/Person';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WorkIcon from '@mui/icons-material/Work';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import "./App.css";

function App() {
  return (
    <Admin authProvider={authProvider} dashboard={Dashboard} dataProvider={dataProvider} layout={Layout}>
      <CustomRoutes>
        <Route path="/change-password" element={<ChangePassword />} />
      </CustomRoutes>
      <Resource icon={DoneAllIcon} name="approvals" {...approvals} />
      <Resource icon={WorkIcon} name="business-plans" {...businessPlans} options={{ label: 'Business plans' }} />
      <Resource icon={HomeWorkIcon} name="companies" {...companies} options={{ label: 'Companies' }} />
      <Resource icon={Person} name="users" {...users} />
      <Resource icon={KeyRoundedIcon} name="layer-permission-request" {...permissions} options={{ label: 'Permission requests' }}/>
      <Resource icon={TextSnippetIcon} name="logs" {...logs}/>
    </Admin>
  );
}

export default App;
