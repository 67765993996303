import * as React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField, RowClickFunction } from 'react-admin';
import { approvalsFields } from '../../constants/data';
import { approveCompanyById, rejectCompanyById } from '../../helpers/adminQueries';
import { DeleteModal } from '../DeleteModal';
import { CustomDrawer } from '../Drawer';
import { IDrawerState, IAccessLayer } from './interfaces';
import './style.css';

export const ListComponent: React.FC<any> = (props) => {
  const [approveModal, setApproveModal] = React.useState<any>({
    id: '',
    title: '',
    isApprove: true
  });
  const [accessLayer, setAccessLayer] = React.useState<IAccessLayer>({
    access_blue_layer: false,
    access_green_layer: false,
    access_yellow_layer: false,
    access_red_layer: false,
  });

  const [drawer, setDrawer] = React.useState<IDrawerState>({
    isOpen: false,
    data: {}
  });

  const handleClick: RowClickFunction = async (id: any, resource: string, record: any) => {
    setDrawer({
      isOpen: true,
      data: record
    });
    setAccessLayer({
      access_green_layer: record.company.access_green_layer,
      access_red_layer: record.company.access_red_layer,
      access_yellow_layer: record.company.access_yellow_layer,
      access_blue_layer: record.company.access_blue_layer
    })
    return '';
  }

  const closeDrawer = () => {
    setDrawer({ isOpen: false, data: {} })
  }

  const onSubmitDrawer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const id = drawer.data.company.id;
    if (!id) return;
    setApproveModal({
      id, title: drawer.data.company.name,
      isApprove: true,
    });
  }

  const onRejectDrawer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const id = drawer.data.company.id;
    if (!id) return;
    setApproveModal({
      id,
      title: drawer.data.company.name,
      isApprove: false
    });
  }

  const onApprove = async () => {
    const res = await approveCompanyById(approveModal.id, accessLayer);
    if (res.status) window.location.reload();
  }

  const onReject = async () => {
    const res = await rejectCompanyById(approveModal.id);
    if (res.status) window.location.reload();
  }

  const onAccessLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {id, checked } = event.target;
    setAccessLayer({
      ...accessLayer,
      [id]: checked
    })
  }

  return (
    <>
      {approveModal.id ? <DeleteModal
        visible={!!approveModal.id}
        data={{ title: drawer.data.company.name }}
        ok={approveModal.isApprove ? onApprove : onReject}
        cancel={() => setApproveModal(false)}
        title={`Are you sure ${approveModal.isApprove ? 'approve' : 'reject'}`}
      /> : null}
      {drawer.isOpen && <CustomDrawer setAccessLayer={onAccessLayerChange} accessLayer={accessLayer} fields={approvalsFields} isOpen={drawer.isOpen} data={drawer.data} closeDrawer={closeDrawer} submit={onSubmitDrawer} submit2={onRejectDrawer} />}
      <List>
        <Datagrid rowClick={handleClick}>
          <FunctionField label="Customer" render={(record: any) => `${record.first_name} ${record.last_name}`} />
          <TextField label="Company name" source="company.name" />
          <DateField label="Created" source="company.created_at" />
        </Datagrid>
      </List>
    </>
  );
}