import { IField } from "../components/Drawer/interfaces";

export const approvalsFields: IField[] = [
  { key: 'Full name', value: ['first_name', 'last_name'] },
  { key: 'Company name', value: 'company.name' },
  { key: 'VAT number', value: 'company.vat_number' },
  { key: 'Registered', value: 'company.created_at', format: 'date' }
];

export const companyFields: IField[] = [
  { key: 'Full name', value: ['owner.first_name', 'owner.last_name'] },
  { key: 'Company name', value: 'name' },
  { key: 'VAT number', value: 'vat_number' },
  { key: 'Status', value: 'status' },
  { key: 'Created', value: 'created_at', format: 'date' }
]

export const bpFields: IField[] = [
  { key: 'Title', value: 'name'},
  { key: 'Description', value: 'description' },
  { key: 'Company name', value: 'company.name'},
  { key: 'VAT number', value: 'company.vat_number' },
  { key: 'Created date', value: 'created_at', format: 'date' },
  { key: 'Last edited date', value: 'updated_at', format: 'date' },
  { key: '# of cards / activities', value: 'activities' }
];

export const passwordFields: IField[] = [
  { key: 'Current password *', value: 'oldPassword' },
  { key: 'New password *', value: 'newPassword' },
  { key: 'Confirm password *', value: 'confirmPassword' }
]