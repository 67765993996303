import http from '../auth/http-common';
import { IPassword } from '../components/ChangePassword/interfaces';
import { IAccessLayer } from "../components/Approvals/interfaces";

const SERVICE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const getDashboardItemsCount = async (): Promise<any> => {
  const response = await http.get(`${SERVICE_URL}/admin/dashboard-items`, { proxy: false });
  return response?.data;
};

export const changePassword = async (data: IPassword): Promise<any> => {
  const response = await http.post(`${SERVICE_URL}/auth/resetPassword`, data, { proxy: false });
  if (response.status === 201) {
    return {
      status: true,
      message: response.data.message
    }
  }
  return {
    status: false,
    message: response.data.message
  }
}

export const approveCompanyById = async (id: string, accessLayer: IAccessLayer) => {
  const response = await http.post(`${SERVICE_URL}/admin/approvals/approve`,
    {
      id,
      accessLayers: {
        blue: accessLayer.access_blue_layer,
        yellow: accessLayer.access_yellow_layer,
        green: accessLayer.access_green_layer,
        red: accessLayer.access_red_layer
      }
    },
    { proxy: false }
  );
  if (response.status === 201) {
    return { status: true }
  }
  return { status: false }
}

export const rejectCompanyById = async (id: string) => {
  const response = await http.post(`${SERVICE_URL}/admin/approvals/reject`, { id }, { proxy: false });
  if (response.status === 201) {
    return { status: true }
  }
  return { status: false }
}

export const approveLayerPermissionAccessById = async (id: string) => {
  const response = await http.post(`${SERVICE_URL}/admin/layer-permission-request/approve`,
    { id },
    { proxy: false }
  );
  if (response.status === 201) {
    return { status: true }
  }
  return { status: false }
}

export const rejectLayerPermissionAccessById = async (id: string) => {
  const response = await http.post(`${SERVICE_URL}/admin/layer-permission-request/reject`, { id }, { proxy: false });
  if (response.status === 201) {
    return { status: true }
  }
  return { status: false }
}