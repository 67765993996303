import React, { FC, useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { layers } from '../../constants/bp-constants';
import { bpFields } from '../../constants/data';
import { dataProvider } from '../../dataProvider';
import { formatDate } from '../../helpers/utils';
import { DeleteModal } from '../DeleteModal';
import { IDeleteModalProps } from '../DeleteModal/interfaces';
import { IField } from '../Drawer/interfaces';

export const ShowComponent: FC = () => {
  const [bp, setBp] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState<IDeleteModalProps>({
    visible: false,
    data: null
  });

  useEffect(() => {
    if (!bp?.id) {
      const id = window.location.href.split('/').at(-2);
      id && dataProvider.getOne('business-plans', { id }).then(({ data }) => {
        setBp({ ...data, id });
      });
    }
  }, [bp?.id]);

  const renderFields = ({ key, value, format, src }: IField, idx: number) => {
    let textValue = null;

    if (typeof value === 'string' && value.includes('.')) {
      const path = value.split('.');
      textValue = bp?.[path[0]]?.[path[1]]
    }
    if (format === 'date') {
      const date = bp?.[value.toString()];
      textValue = formatDate(date)
    }
    if (textValue === null && typeof value === 'string') {
      textValue = bp?.[value];
    }
    if (format === 'link' && typeof value === 'string') {
      textValue = <a href={src}>{textValue}</a>
    }
    return <div key={key} className="bp-text-wrap"><h3>{key}: </h3><span>{textValue || '-'}</span></div>;
  }

  const renderLayerItems = (name: string, idx: number) => {
    const layer = bp[name];
    return <div key={name} className='layer-item' style={{ background: layer ? 'green' : '#a8a6a6' }}>
      <span>{name}</span>
    </div>;
  }

  const showDeleteModal = () => {
    setDeleteModal({
      visible: true,
      data: {
        id: bp.id,
        title: bp.name
      }
    });
  }
  const goBack = () => {
    window.location.replace('#/business-plans');
  }
  const deleteBP = async () => {
    await dataProvider.deleteMany('business-plans', { ids: [bp.id] });
    goBack();
  }
  const cancelDelte = () => {
    setDeleteModal({ visible: false, data: null });
  }

  return (
    <>
      {deleteModal.visible
        ? <DeleteModal
          visible={deleteModal.visible}
          data={deleteModal.data}
          cancel={cancelDelte}
          ok={deleteBP}
        />
        : null}

      <div className="bp-wrap">
        <h2>Business plans</h2>
        {(bp?.id) ? bpFields?.map(renderFields) : null}
        <div className="bp-btns">{layers.map(renderLayerItems)}</div>
        <div className="bp-btns">
          <Button label="Delete" onClick={showDeleteModal} />
          <Button label="Close" onClick={goBack} />
        </div>
      </div>
    </>
  )
};