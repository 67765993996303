import React, { FC } from 'react';
import { Create, DateInput, SimpleForm, TextInput } from 'react-admin';

export const CreateComponent: FC = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Company name" source="name" />
        <TextInput label="Company description" source="description" fullWidth />
        <DateInput label="Company founding" source="founding_date" />
        <TextInput label="VAT number" source="vat_number" />
      </SimpleForm>
    </Create>
  )
}