import React, { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import { Button } from 'react-admin';
import { IField, IDrawerProps } from './interfaces';
import { formatDate } from '../../helpers/utils';
import './style.css';

export const CustomDrawer: FC<IDrawerProps> = (
  {
    isOpen,
    fields,
    accessLayer,
    data,
    closeDrawer,
    submit,
    submit2,
    setAccessLayer
  }) => {
  const mapFields = ({ key, value, format }: IField, idx: number) => {

    if (typeof value === 'string' && value.includes('.')) {
      const path = value.split('.');
      value = data?.[path[0]]?.[path[1]]
    }

    if (Array.isArray(value)) {
      value = value.map((v: string) => data[v])?.join(' ');
    }

    if (format === 'date') {
      value = formatDate(value.toString());
    }

    return <div key={key} className="approvals-drawer-field">
      <h4>{key}: </h4>
      <span>{value || '-'}</span>
    </div>
  }
  return (
    <Drawer
      sx={{ width: 400, flexShrink: 0 }}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className='approvals-drawer'>
        <div className="approvals-drawer-head">
          <h3>Approve company</h3>
          <Button label='Close' onClick={closeDrawer} />
        </div>
        {fields?.length ? fields?.map(mapFields) : null}
        <h3>Layer permissions</h3>
        <div className="approvals-drawer-accesses">
          <div>
            <input onChange={setAccessLayer} id="access_blue_layer" type="checkbox" checked={accessLayer.access_blue_layer}/>
            <label htmlFor="access_blue_layer">Blue Layer</label>
          </div>
          <div>
            <input onChange={setAccessLayer} id="access_green_layer" type="checkbox" checked={accessLayer.access_green_layer}/>
            <label htmlFor="access_green_layer">Green Layer</label>
          </div>
          <div>
            <input onChange={setAccessLayer} id="access_red_layer" type="checkbox" checked={accessLayer.access_red_layer}/>
            <label htmlFor="access_red_layer">Red Layer</label>
          </div>
          <div>
            <input onChange={setAccessLayer} id="access_yellow_layer" type="checkbox" checked={accessLayer.access_yellow_layer}/>
            <label htmlFor="access_yellow_layer">Yellow Layer</label>
          </div>
        </div>
        <div className="approvals-drawer-btns">
          <Button label='Approve' onClick={submit} />
          <Button label='Reject' onClick={submit2} />
        </div>
      </div>
    </Drawer>
  )
};