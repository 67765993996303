import React from "react";
import {Datagrid, DateField, FunctionField, List, RowClickFunction, TextField} from "react-admin";
import {IDrawerState} from "../Approvals/interfaces";
import {DeleteModal} from "../DeleteModal";
import {CustomPermissionDrawer} from "../DrawerPermission";
import {
  approveLayerPermissionAccessById,
  rejectLayerPermissionAccessById
} from "../../helpers/adminQueries";

export const ListComponent: React.FC<any> = (props) => {
  const [approveModal, setApproveModal] = React.useState<any>({
    id: '',
    title: '',
    isApprove: true
  });

  const [drawer, setDrawer] = React.useState<IDrawerState>({
    isOpen: false,
    data: {}
  });

  const handleClick: RowClickFunction = async (id: any, resource: string, record: any) => {
    setDrawer({
      isOpen: true,
      data: record
    });
    return '';
  }

  const closeDrawer = () => {
    setDrawer({ isOpen: false, data: {} })
  }

  const onSubmitDrawer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const id = drawer.data.id;
    if (!id) return;
    setApproveModal({
      id, title: drawer.data.owner.first_name + " " + drawer.data.owner.last_name,
      isApprove: true,
    });
  }

  const onRejectDrawer = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const id = drawer.data.id;
    if (!id) return;
    setApproveModal({
      id,
      title: drawer.data.owner.first_name + " " + drawer.data.owner.last_name,
      isApprove: false
    });
  }

  const onApprove = async () => {
    const res = await approveLayerPermissionAccessById(approveModal.id);
    if (res.status) window.location.reload();
  }

  const onReject = async () => {
    const res = await rejectLayerPermissionAccessById(approveModal.id);
    if (res.status) window.location.reload();
  }


  return (
    <>
      {approveModal.id ? <DeleteModal
        visible={!!approveModal.id}
        data={{ title: drawer.data.owner.first_name + " " + drawer.data.owner.last_name}}
        ok={approveModal.isApprove ? onApprove : onReject}
        cancel={() => setApproveModal(false)}
        title={`Are you sure to ${approveModal.isApprove ? 'approve' : 'reject'} access for`}
      /> : null}
      {drawer.isOpen && <CustomPermissionDrawer permissionRequest={drawer.data} isOpen={drawer.isOpen} data={drawer.data} closeDrawer={closeDrawer} submit={onSubmitDrawer} submit2={onRejectDrawer} />}
      <List>
        <Datagrid rowClick={handleClick}>
          <TextField label="Company name" source="business_plan.company.name"/>
          <TextField label="Business plan" source="business_plan.name"/>
          <FunctionField label="Customer" render={(record: any) => `${record.owner.first_name} ${record.owner.last_name}`} />
          <TextField source="layer_type"/>
          <DateField source="created_at" showTime />
          <DateField source="updated_at" showTime />
        </Datagrid>
      </List>
    </>
  );
}