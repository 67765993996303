import React, { FC } from 'react';
import { Button } from 'react-admin';
import { IDeleteModalProps } from './interfaces';
import './style.css';

export const DeleteModal: FC<IDeleteModalProps> = ({ data, ok, cancel, title = 'Are you sure delete' }) => {
  return (
    <div className='delete-modal-overlay'>
      <div className="delete-modal">
        {title} <b>{data.title}</b> ?
        <div className="bp-btns">
          <Button label="Ok" onClick={ok} />
          <Button label="Cancel" onClick={cancel} />
        </div>
      </div>
    </div>
  )
}
