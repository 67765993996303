import { CreateComponent } from "./Create";
import { ListComponent } from "./List";
import { ShowComponent } from "./Show";

const components = {
  list: ListComponent,
  create: CreateComponent,
  show: ShowComponent
}

export default components;