import * as React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { renderLayerCount } from '../../helpers/utils';
import './style.css';

export const ListComponent: React.FC<any> = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick={'show'}>
        <TextField label="Business plan name" source="name" />
        <FunctionField label="Layers" render={renderLayerCount} />
        <TextField label="Company name" source="company.name" />
        <DateField label="Created at" source="created_at" />
        <DateField label="Last edit date" source="updated_at" />
      </Datagrid>
    </List>
  );
}