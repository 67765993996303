import React, { FC, useEffect, useState } from 'react';
import { Button } from 'react-admin';
import { companyFields } from '../../constants/data';
import { dataProvider } from '../../dataProvider';
import { formatDate } from '../../helpers/utils';
import { DeleteModal } from '../DeleteModal';
import { IDeleteModalProps } from '../DeleteModal/interfaces';
import { IField } from '../Drawer/interfaces';
import { ShowTable } from './showTable';

export const ShowComponent: FC = () => {
  const [company, setCompany] = useState<any>({});
  const [deleteModal, setDeleteModal] = useState<IDeleteModalProps>({
    visible: false,
    data: null
  });

  useEffect(() => {
    if (!company?.id) {
      const id = window.location.href.split('/').at(-2);
      id && dataProvider.getOne('companies', { id }).then(({ data }) => {
        setCompany({ ...data, id });
      });
    }
  }, [company?.id]);

  const showDeleteModal = () => {
    setDeleteModal({
      visible: true,
      data: {
        id: company.id,
        title: company.name
      }
    });
  }
  const goBack = () => {
    window.location.replace('#/companies');
  }
  const deleteBP = async () => {
    await dataProvider.deleteMany('companies', { ids: [company.id] });
    goBack();
  }
  const cancelDelte = () => {
    setDeleteModal({ visible: false, data: null });
  }

  const mapFields = ({ key, value, format }: IField, idx: number) => {
    if (typeof value !== 'string') return '';
    let textValue = null;

    const getDeepValue = (str: string) => {
      const path = str.split('.');
      if (path.length > 1) return company?.[path[0]]?.[path[1]]
      return company[str];
    }

    if (value.includes('.')) {
      textValue = getDeepValue(value);
    }

    if (Array.isArray(value)) {
      textValue = value.map((v: string) => getDeepValue(v))?.join(' ');
    }

    if (format === 'date') {
      const date = company?.[value.toString()];
      textValue = formatDate(date);
    }

    if (textValue === null) {
      textValue = getDeepValue(value);
    }

    return <div key={key} className="approvals-drawer-field">
      <h4>{key}: </h4>
      <span>{textValue || '-'}</span>
    </div>
  }

  return (
    <>
      {deleteModal.visible
        ? <DeleteModal
          visible={deleteModal.visible}
          data={deleteModal.data}
          cancel={cancelDelte}
          ok={deleteBP}
        />
        : null}

      <div className="company-wrap">
        <h2>Company</h2>
        {company?.id ? companyFields?.map(mapFields) : null}
      </div>

      <div className="company-wrap">
        <h2>Business plans</h2>
        {company?.business_planes ? <ShowTable data={company?.business_planes} /> : 'No data'}
      </div>

      <div className="company-btns">
        <Button label="Delete" onClick={showDeleteModal} />
        <Button label="Close" onClick={goBack} />
      </div>
    </>
  )
};