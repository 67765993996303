import { CreateComponent } from "./Create";
import { ListComponent } from "./List";
import { ShowComponent } from "./Show";

const components = {
  list: ListComponent,
  show: ShowComponent,
  create: CreateComponent
}

export default components;