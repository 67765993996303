import React, { FC } from 'react';
import { formatDate, renderLayerCount } from '../../helpers/utils';
import './style.css';

export const ShowTable: FC<{ data: any[] }> = ({ data }) => {
  const openBp = (id: string) => {
    window.location.replace(`#/business-plans/${id}/show`);
  }

  const renderContent = (bp: any) => {
    const { name, created_at, updated_at } = bp;
    return <tr key={bp?.name} onClick={openBp.bind(null, bp.id)}>
      <td>{name}</td>
      <td>{renderLayerCount(bp)}</td>
      <td>{formatDate(created_at)}</td>
      <td>{formatDate(updated_at)}</td>
    </tr>
  }

  return (
    <table className='company-table'>
      <thead>
        <tr>
          <th>Business plan</th>
          <th>Layers</th>
          <th>Created at</th>
          <th>Latest edit date</th>
        </tr>
      </thead>
      <tbody>
        {data.length ? data.map(renderContent) : null}
      </tbody>
    </table>
  )
}
