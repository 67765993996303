import React, { FC } from 'react';
import { Show, SimpleShowLayout, TextField, DateField } from 'react-admin';

export const ShowComponent: FC = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField label="Email" source="username" />
      <TextField label="Role" source="role.value" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);