import React, {FC} from "react";
import {Datagrid, DateField, List, TextField} from "react-admin";


export const ListComponent: FC = () => {
  return (
    <List>
      <Datagrid>
        <TextField source="method" />
        <TextField source="user.username" label="Username"/>
        <TextField source="url" />
        <TextField source="body"/>
        <DateField source="created_at" showTime />
      </Datagrid>
    </List>
  );
};